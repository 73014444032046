// import React, { useState, useEffect } from "react";
// import { ThreeDots } from "react-loader-spinner";
// import "../../Pages/Admin/Style.css";
// import "./add_location.css";
// import Select from "react-select";
// import { generateGiftCard, getAdminPass } from "../../Api/apiUrls";

// function GenerateGiftCard({ onClose }) {
//   const [giftCardCount, setGiftCardCount] = useState("");
//   const [selectedPass, setSelectedPass] = useState(null);
//   const [isAddingLocation, setIsAddingLocation] = useState(false);
//   const [passOptions, setPassOptions] = useState([]);
//   function reloadPage() {
//     window.location.reload();
//   }
//   // Fetch pass data on component mount
//   useEffect(() => {
//     const fetchPassData = async () => {
//       try {
//         const response = await fetch(getAdminPass);
//         const data = await response.json();
  
//         if (data.success && Array.isArray(data.data.Pass)) {
//           // Filter passes that are active
//           const activePasses = data.data.Pass.filter((pass) => pass.status === "Active");
  
//           // Map the active passes to the desired format
//           const options = activePasses.map((pass) => ({
//             value: pass.id,
//             label: `${pass.title} (Duration: ${pass.duration_type}, Price: ${pass.mrp})`,
//           }));
  
//           setPassOptions(options);
//         } else {
//           console.error("Failed to fetch pass data:", data.message);
//         }
//       } catch (error) {
//         console.error("Error fetching pass data:", error);
//       }
//     };
  
//     fetchPassData();
//   }, []);
  

//   // Handle adding a new location
//   const handleAddPass = async () => {
//     if (!giftCardCount || !selectedPass) {
//       alert("Please fill in all fields");
//       return;
//     }

//     // Ensure giftCardCount is at least 1
//     if (giftCardCount < 1) {
//       alert("Gift card count must be at least 1");
//       return;
//     }

//     setIsAddingLocation(true);

//     try {
//       const formData = new FormData();
//       formData.append("no_of_cards", giftCardCount);
//       formData.append("passes", selectedPass.value);

//       const response = await fetch(generateGiftCard, {
//         method: "POST",
//         body: formData,
//       });

//       const responseData = await response.json();

//       if (response.ok && responseData.status === 201) {
//         alert("Gift Card Generated Successfully");
//         setGiftCardCount("");
//         setSelectedPass(null);
//         onClose();
//         reloadPage();
//       } else {
//         alert("Error generating gift card");
//       }
//     } catch (error) {
//       console.error("Error generating gift card:", error);
//       alert("An error occurred");
//     } finally {
//       setIsAddingLocation(false);
//     }
//   };

//   return (
//     <div className="popup-background">
//       <div className="popup">
//         <div
//           className="modal-content"
//           style={{
//             width: "100%",
//             padding: "2%",
//             backgroundColor: "#fff",
//             borderRadius: "5px",
//           }}
//         >
//           <div
//             className="modal-header"
//             style={{
//               marginBottom: "3%",
//               borderBottom: "solid 1px #7e7a7a8c",
//             }}
//           >
//             <h5 className="modal-title" id="modalAddTitle">
//               <b>Generate Gift Card</b>
//             </h5>
//             <button
//               type="button"
//               className="close"
//               aria-label="Close"
//               onClick={onClose}
//             >
//               <span aria-hidden="true">&times;</span>
//             </button>
//           </div>

//           <div className="modal-body" style={{ padding: "2%" }}>
//             <form>
//               <div className="form-group">
//                 <label className="small mb-1" htmlFor="giftCardCount">
//                   Gift Card Count
//                 </label>
//                 <input
//                   id="giftCardCount"
//                   type="number"
//                   className="form-control"
//                   value={giftCardCount}
//                   onChange={(e) => setGiftCardCount(e.target.value)}
//                   min="1" // Ensures no value less than 1 can be entered
//                 />
//               </div>

//               <div className="form-group">
//                 <label className="small mb-1" htmlFor="selectPass">
//                   Pass
//                 </label>
//                 <Select
//                   id="selectPass"
//                   options={passOptions}
//                   value={selectedPass}
//                   onChange={(option) => setSelectedPass(option)}
//                 />
//               </div>
//             </form>
//           </div>

//           <div
//             className="modal-footer"
//             style={{
//               padding: "2%",
//               marginTop: "3%",
//               borderTop: "solid 1px #7e7a7a8c",
//             }}
//           >
//             <button
//               type="button"
//               className="btn btn-danger"
//               onClick={onClose}
//             >
//               Close
//             </button>

//             {isAddingLocation ? (
//               <ThreeDots color="#000" height={50} width={50} />
//             ) : (
//               <button
//                 type="button"
//                 className="btn btn-primary"
//                 style={{ background: "#000", border: "none" }}
//                 onClick={handleAddPass}
//               >
//                 Generate
//               </button>
//             )}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default GenerateGiftCard;


import React, { useState, useEffect } from "react";
import { ThreeDots } from "react-loader-spinner";
import Select from "react-select";
import { generateGiftCard, getAdminPass, generateSplittedGiftCard } from "../../Api/apiUrls";
import "../../Pages/Admin/Style.css";
import "./add_location.css";

function GenerateGiftCard({ onClose }) {
  const [giftCardCount, setGiftCardCount] = useState("");
  const [selectedPass, setSelectedPass] = useState(null);
  const [isAddingLocation, setIsAddingLocation] = useState(false);
  const [passOptions, setPassOptions] = useState([]);
  const [isSplit, setIsSplit] = useState(false);
  const [splitOptions, setSplitOptions] = useState([]);
  const [selectedSplitPass, setSelectedSplitPass] = useState(null);
  const [splitDetails, setSplitDetails] = useState({
    email: "",
    sold_at: "",
    name: "",
    mobileNumber: "",
    address: "",
  });

  useEffect(() => {
    const fetchPassData = async () => {
      try {
        const response = await fetch(getAdminPass);
        const data = await response.json();
        if (data.success && Array.isArray(data.data.Pass)) {
          const activePasses = data.data.Pass.filter((pass) => pass.status === "Active");
          const options = activePasses.map((pass) => ({
            value: pass.id,
            label: `${pass.title} (Duration: ${pass.duration_type}, Price: ${pass.mrp})`,
            duration: pass.duration_type,
          }));
          setPassOptions(options);
        } else {
          console.error("Failed to fetch pass data:", data.message);
        }
      } catch (error) {
        console.error("Error fetching pass data:", error);
      }
    };
    fetchPassData();
  }, []);

  useEffect(() => {
    if (selectedPass) {
      let allowedOptions = [];
      if (selectedPass.duration === "Quarterly") {
        allowedOptions = passOptions.filter((pass) => pass.duration === "Monthly");
      } else if (selectedPass.duration === "Half_Yearly") {
        allowedOptions = passOptions.filter((pass) => ["Quarterly", "Monthly"].includes(pass.duration));
      } else if (selectedPass.duration === "Annual") {
        allowedOptions = passOptions.filter((pass) => ["Half_Yearly", "Quarterly", "Monthly"].includes(pass.duration));
      }
      setSplitOptions(allowedOptions);
      setIsSplit(false);
      setSelectedSplitPass(null);
    }
  }, [selectedPass]);

  const handleAddPass = async () => {
    if (!selectedPass) {
      alert("Please select a pass.");
      return;
    }
  
    if (isSplit) {
      if (!selectedSplitPass) {
        alert("Please select a split pass.");
        return;
      }
  
      // Validate required fields in splitDetails
      const { email, sold_at, name, mobileNumber } = splitDetails;
      if (!email || !sold_at || !name || !mobileNumber) {
        alert("Please fill in all required split details (Email, Sold At, Name, Mobile Number).");
        return;
      }
    } else {
      if (!giftCardCount) {
        alert("Please enter the number of gift cards.");
        return;
      }
      if (giftCardCount < 1) {
        alert("Gift card count must be at least 1.");
        return;
      }
    }

    setIsAddingLocation(true);
    try {
      const formData = new FormData();

      let apiUrl = generateGiftCard; // Default API for normal case

      if (isSplit && selectedSplitPass) {
        apiUrl = generateSplittedGiftCard;
        formData.append("split_from_pass", selectedPass.value);
        formData.append("split_into_passes", selectedSplitPass.value);
        Object.keys(splitDetails).forEach((key) => {
          formData.append(key, splitDetails[key]);
        });
      } else{
        formData.append("no_of_cards", giftCardCount);
        formData.append("passes", selectedPass.value);
      }

      const response = await fetch(apiUrl, { method: "POST", body: formData });
      const responseData = await response.json();

      if (response.ok && responseData.status === 201) {
        alert("Gift Card Generated Successfully");
        setGiftCardCount("");
        setSelectedPass(null);
        setIsSplit(false);
        setSelectedSplitPass(null);
        setSplitDetails({ email: "", sold_at: "", name: "", mobileNumber: "", address: "" });
        onClose();
        window.location.reload();
      } else {
        alert("Error generating gift card");
      }
    } catch (error) {
      console.error("Error generating gift card:", error);
      alert("An error occurred");
    } finally {
      setIsAddingLocation(false);
    }
  };

  return (
    <div className="popup-background">
      <div className="popup">
        <div className="modal-content">
          <div className="modal-header">
            <h5><b>Generate Gift Card</b></h5>
            <button className="close" onClick={onClose}>&times;</button>
          </div>
          <div className="modal-body">
            <form>
              <div className="form-group">
                <label>Gift Card Count</label>
                <input type="number" className="form-control" value={giftCardCount} onChange={(e) => setGiftCardCount(e.target.value)} min="1" />
              </div>
              <div className="form-group">
                <label>Pass</label>
                <Select options={passOptions} value={selectedPass} onChange={setSelectedPass} />
              </div>
              <div className="form-group">
                <input
                  type="checkbox"
                  checked={isSplit}
                  onChange={() => setIsSplit(!isSplit)}
                  disabled={selectedPass?.duration === "Monthly"}
                />{" "}
                Split
              </div>
              {isSplit && (
                <>
                  <div className="form-group">
                    <label>Split Into</label>
                    <Select options={splitOptions} value={selectedSplitPass} onChange={setSelectedSplitPass} />
                  </div>
                  <div className="form-group">
                    <label>Email</label>
                    <input type="email" className="form-control" value={splitDetails.email} onChange={(e) => setSplitDetails({ ...splitDetails, email: e.target.value })} />
                  </div>
                  <div className="form-group">
                    <label>Name</label>
                    <input type="text" className="form-control" value={splitDetails.name} onChange={(e) => setSplitDetails({ ...splitDetails, name: e.target.value })} />
                  </div>
                  <div className="form-group">
                    <label>Mobile Number</label>
                    <input type="text" className="form-control" value={splitDetails.mobileNumber} onChange={(e) => setSplitDetails({ ...splitDetails, mobileNumber: e.target.value })} />
                  </div>
                  <div className="form-group">
                    <label>Sold At</label>
                    <input type="number" className="form-control" value={splitDetails.sold_at} onChange={(e) => setSplitDetails({ ...splitDetails, sold_at: e.target.value })} />
                  </div>
                  <div className="form-group">
                    <label>Address</label>
                    <input type="text" className="form-control" value={splitDetails.address} onChange={(e) => setSplitDetails({ ...splitDetails, address: e.target.value })} />
                  </div>
                </>
              )}
            </form>
          </div>
          <div className="modal-footer">
            <button className="btn btn-danger" onClick={onClose}>Close</button>
            {isAddingLocation ? <ThreeDots color="#000" height={50} width={50} /> : <button className="btn btn-primary" onClick={handleAddPass}>Generate</button>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default GenerateGiftCard;



