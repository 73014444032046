import React, { useState, useEffect, useRef } from "react";
import { ThreeDots } from "react-loader-spinner";
import "../../Pages/Admin/Style.css";
import "./add_appointment.css";
import Select2 from 'react-select2-wrapper';
import 'select2/dist/css/select2.css';
import $ from 'jquery';
import "../../Api/apiUrls";
import { API_URL } from "../../Api/apiUrls";
import Select from "react-select";

function Get_appointment_by_slot({ onClose }) {
  const [modalGet_appointment_by_slotIsOpen, setGet_appointment_by_slotModalIsOpen] =
    useState("");

  const [isGettingappointment, setIsGettingappointment] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const closeGet_appointment_by_slot = () => {
    setGet_appointment_by_slotModalIsOpen(false);
  };

  function reloadPage() {
    window.location.reload();
  }

  useEffect(() => {
    // Initialize Select2 for all elements
    if ($('#instructorSelect').length) {
      $('#instructorSelect').select2({
        placeholder: 'Select an instructor',
        allowClear: true
      });
    }

    if ($('#customerSelect').length) {
      $('#customerSelect').select2({
        placeholder: 'Select a customer',
        allowClear: true
      });
    }

    // Cleanup function to destroy Select2 instances before component unmount
    return () => {
      if ($('#instructorSelect').data('select2')) {
        $('#instructorSelect').select2('destroy');
      }
      if ($('#customerSelect').data('select2')) {
        $('#customerSelect').select2('destroy');
      }
    };
  }, []);


  const [instructors, setInstructors] = useState([]);
  const [selectedInstructor, setSelectedInstructor] = useState(null);
  useEffect(() => {
    fetch(`${API_URL}/user/get-instructor/`)
      .then(response => response.json())
      .then(data => {
        const options = data.data.users.map(user => ({
          id: user.id,
          text: user.name,
          image: `${API_URL}/${user.profile_image}`,
        }));
        setInstructors(options);
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Error fetching instructors:', error);
        setIsLoading(false);
      });
  }, []);
  const handleInstructorChange = (instructorId) => {
    setSelectedInstructor(instructorId);
    fetchWorkshopsByInstructor(instructorId);
  };

  const formatOption = (state) => {
    if (!state.id) return state.text; // Default text

    const instructor = instructors.find(inst => inst.id == state.id);
    if (!instructor) return state.text;

    return $(
      `<span><img src="${instructor.image}" class="img-flag" style="width:20px; margin-right: 10px;" />${state.text}</span>`
    );
  };

  const formatOptionSelection = (state) => {
    if (!state.id) return state.text; // Default text

    const instructor = instructors.find(inst => inst.id == state.id);
    if (!instructor) return state.text;

    return $(
      `<span><img src="${instructor.image}" class="img-flag" style="width:20px; margin-right: 10px;" />${state.text}</span>`
    );
  };

  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  useEffect(() => {
    fetch(`${API_URL}/user/get-users/`)
      .then(response => response.json())
      .then(data => {
        const options = data.data.users.map(user => ({
          id: user.id,
          text: user.name,
          image: `${API_URL}/${user.profile_image}`,
        }));
        setCustomers(options);
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Error fetching instructors:', error);
        setIsLoading(false);
      });
  }, []);

  const formatOptionCustomer = (state) => {
    if (!state.id) return state.text; // Default text

    const customer = customers.find(inst => inst.id == state.id);
    if (!customer) return state.text;

    return $(
      `<span><img src="${customer.image}" class="img-flag" style="width:20px; margin-right: 10px;" />${state.text}</span>`
    );
  };

  const formatOptionSelectionCustomer = (state) => {
    if (!state.id) return state.text; // Default text

    const customer = customers.find(inst => inst.id == state.id);
    if (!customer) return state.text;

    return $(
      `<span><img src="${customer.image}" class="img-flag" style="width:20px; margin-right: 10px;" />${state.text}</span>`
    );
  };


  const [workshops, setWorkshops] = useState([]);
  const [selectedWorkshop, setSelectedWorkshop] = useState(null);

  const fetchWorkshopsByInstructor = (instructorId) => {
    fetch(`${API_URL}/workshop/get-workshop_for_website_by_workshop_instructor_id/?workshop_instructor_id=${instructorId}`)
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          const workshopOptions = data.data.Workshop.map(workshop => ({
            id: workshop.id,
            text: workshop.name,
          }));
          setWorkshops(workshopOptions);
        } else {
          setWorkshops([]);
        }
      })
      .catch(error => {
        console.error('Error fetching workshops:', error);
        setWorkshops([]);
      });
  };
  const handleWorkshopChange = (e) => {
    const workshopId = e.target.value;
    setSelectedWorkshop(workshopId);
    fetchAppointmentSlotsByWorkshop(workshopId);  // Fetch appointment slots for the selected workshop
  };

  const [appointmentSlots, setAppointmentSlots] = useState([]);

  const [selectedAppointmentSlot, setSelectedAppointmentSlot] = useState(null);
  const fetchAppointmentSlotsByWorkshop = (workshopId) => {
    fetch(`${API_URL}/workshop_slot/get-workshop_slot_by_workshop_id/?WorkshopSlot_workshop_id=${workshopId}`)
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          const slotOptions = data.data.WorkshopSlot.map(slot => ({
            id: slot.id,
            date: slot.date,         // Keep date in raw format
            startTime: slot.startTime, // Keep start time in raw format
            endTime: slot.endTime,    // Keep end time in raw format
          }));
          setAppointmentSlots(slotOptions); // Set slot options as is
        } else {
          setAppointmentSlots([]); // Set empty if no data
        }
      })
      .catch(error => {
        console.error('Error fetching appointment slots:', error);
        setAppointmentSlots([]);
      });
  };


  const handleSubmit = async (e) => {
        e.preventDefault();
        if(selectedAppointmentSlot){
            try {
                const response = await fetch(
                    `${API_URL}/appointment/get-appointment-excel-by-workshop-slot-id/?workshop_slot_id=${selectedAppointmentSlot}`, 
                    { method: 'GET' }
                );
    
                if (!response.ok) {
                    throw new Error(`Failed to fetch file: ${response.statusText}`);
                }
    
                // Extract filename from Content-Disposition header
                const contentDisposition = response.headers.get('Content-Disposition');
                let filename = 'appointment_data.xlsx'; // Default filename
    
                if (contentDisposition) {
                    const match = contentDisposition.match(/filename="(.+)"/);
                    if (match && match[1]) {
                        filename = match[1];
                    }
                }
    
                // Convert response to a blob
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
    
                // Create a temporary link element
                const a = document.createElement('a');
                a.href = url;
                a.download = filename; // Use extracted filename
                document.body.appendChild(a);
                a.click();
    
                // Cleanup
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
    
                alert(`Excel file "${filename}" downloaded successfully!`);
                onClose();
            } catch (error) {
                console.error('Error:', error);
                alert('An error occurred while downloading the Excel file.');
            }
        } else {
            alert('Please fill the form!');
        }
    };





  const resetForm = () => {
    setSelectedInstructor(null);
    setSelectedWorkshop(null);
    setSelectedAppointmentSlot(null);
  };



  return (
    <div>
      {modalGet_appointment_by_slotIsOpen && (
        <get_appointment onClose={closeGet_appointment_by_slot} />
      )}
      <div className="popup-background">
        <div className="popup">
          <div
            class="modal-content"
            style={{
              width: "100%",
              height: "80%",
              padding: "2%",
              backgroundColor: "#fff",
              borderRadius: "5px",
            }}
          >
            <div
              class="modal-header"
              style={{
                width: "100%",
                marginBottom: "3%",
                borderBottom: "solid 1px #7e7a7a8c",
              }}
            >
              <h5
                class="modal-title"
                id="modalAddTitle"
                style={{ marginBottom: "2%" }}
              >
                <b>Get Appointments</b>
              </h5>

              <button
                type="button"
                onClick={() => {
                  onClose();
                //   reloadPage();
                }}
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div
              class="modal-body"
              style={{ overflowY: "scroll", padding: "2%" }}
            >
              <form>

                <div className="form-group">
                  <label className="small mb-1" htmlFor="instructorSelect">
                    Select Instructor <span style={{ color: 'red', fontSize: '10px' }}>*</span>
                  </label>
                  {!isLoading ? (
                    <Select2
                      id="instructorSelect"
                      data={instructors}
                      value={selectedInstructor}
                      // onChange={(e) => setSelectedInstructor(e.target.value)}
                      onChange={(e) => handleInstructorChange(e.target.value)}
                      options={{
                        placeholder: 'Select an instructor',
                        allowClear: true,
                        templateResult: formatOption,
                        templateSelection: formatOptionSelection,
                      }}
                      style={{ width: '100%' }}
                    />
                  ) : (
                    <p>Loading instructors...</p>
                  )}
                </div>

                <div className="form-group">
                  <label className="small mb-1" htmlFor="workshopSelect">
                    Select Workshop <span style={{ color: 'red', fontSize: '10px' }}>*</span>
                  </label>
                  <Select2
                    id="workshopSelect"
                    data={workshops}
                    value={selectedWorkshop}
                    onChange={handleWorkshopChange}
                    options={{
                      placeholder: 'Select a workshop',
                      allowClear: true,
                    }}
                    style={{ width: '100%' }}
                  />
                </div>

                <div className="form-group">
                  <label className="small mb-1" htmlFor="appointmentSelect">
                    Select Appointment Slot/Date <span style={{ color: 'red', fontSize: '10px' }}>*</span>
                  </label>
                  <Select2
                    id="appointmentSelect"
                    data={appointmentSlots.map(slot => {
                      // Format date and time
                      const formattedDate = new Date(slot.date).toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                      });
                      const startTime = new Date(`1970-01-01T${slot.startTime}`).toLocaleTimeString('en-US', {
                        hour: 'numeric',
                        minute: '2-digit',
                      });
                      const endTime = new Date(`1970-01-01T${slot.endTime}`).toLocaleTimeString('en-US', {
                        hour: 'numeric',
                        minute: '2-digit',
                      });

                      // Return formatted options for Select2
                      return {
                        id: slot.id,
                        text: `${formattedDate} | ${startTime} - ${endTime}`, // Display in the dropdown
                      };
                    })}
                    value={selectedAppointmentSlot} // Selected value for controlled component
                    onChange={(e) => setSelectedAppointmentSlot(e.target.value)} // Handle change
                    options={{
                      placeholder: 'Select an appointment slot',
                      allowClear: true,
                    }}
                    style={{ width: '100%' }} // Full-width dropdown
                  />
                </div>


              </form>

            </div>

            <div
              class="modal-footer"
              style={{
                padding: "2%",
                marginTop: "3%",
                borderTop: "solid 1px #7e7a7a8c",
              }}
            >
              <button
                type="button"
                class="btn btn-danger"
                data-dismiss="modal"
                onClick={() => {
                  onClose();
                //   reloadPage();
                }}
              >
                Close
              </button>
              {isGettingappointment === true ? (
                <div>
                  <ThreeDots color="#000" height={50} width={50} />
                </div>
              ) : (
                <button
                  id="btnGet"
                  type="button"
                  class="btn btn-primary "
                  style={{ background: "#000 ", border: "none" }}
                  onClick={handleSubmit}
                >
                  Download
                </button>
              )}
            </div>
          </div>
        </div>
      </div >
    </div >
  );
}

export default Get_appointment_by_slot;
