import React, { useState, useEffect, useRef } from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";
import Select from "react-select";
import { TailSpin } from "react-loader-spinner";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./Style.css";
import AddManualInvoice from "../../Components/Admin_components/add_manual_invoice.js";
import Update_gift_card from "../../Components/Admin_components/update_gift_card.js";
import DeleteManualInvoice from "../../Components/Admin_components/delete_manual_invoice.js";
import NewsComments from "../../Components/Admin_components/newsComment.js";
import NewsSave from "../../Components/Admin_components/newsSave.js";
import "../../Api/apiUrls";
import { API_URL, getManualInvoice, updateManualInvoice } from "../../Api/apiUrls";
import Toggle from "react-toggle";

function ManualInvoice() {
  const [modalAdd_newsIsOpen, setAdd_newsModalIsOpen] = useState("");
  const [modalUpdateManualInvoiceIsOpen, setUpdateManualInvoiceModalIsOpen] = useState(false);
  const [modalDeleteManualInvoiceIsOpen, setDeleteManualInvoiceIsOpen] = useState(false);
  const [modalNewsCommentsIsOpen, setNewsCommentsIsOpen] = useState(false);
  const [modalNewsSaveIsOpen, setNewsSaveIsOpen] = useState(false);
  const [selectedName, setSelectedName] = useState(null);
  const [selectednewsImage, setSelectedNewsImage] = useState(null);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [selectedRemark, setSelectedRemark] = useState(null);
  const [selectedSoldAt, setSelectedSoldAt] = useState(null);
  const [selectedMobileNumber, setSelectedMobileNumber] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [selectedAuthor, setSelectedAuthor] = useState(null);
  const [toggleStates, setToggleStates] = useState({});
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedNewsKeyword, setSelectedNewsKeyword] = useState(null);
  const [manualInvoice, setManualInvoice] = useState([]);
  const [selectedManualInvoiceId, setSelectedManualInvoiceId] = useState(null);
  const [id, setId] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [pass, setPass] = useState([]);
  
  const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);

  const toggleSwitch = async (manualInvoiceId) => {
    try {
      const newToggleState = toggleStates[manualInvoiceId] === 1 ? 0 : 1;
      const newStatus = newToggleState === 1 ? "Active" : "Inactive";
  
      const response = await fetch(updateManualInvoice, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          status: newStatus,
          discount_coupon_id: manualInvoiceId,
        }),
      });
  
      if (!response.ok) {
        throw new Error("Failed to update toggle state.");
      }
  
      const data = await response.json();
  
      if (data.success) {
        // Update the toggle state
        setToggleStates((prevState) => ({
          ...prevState,
          [manualInvoiceId]: newToggleState,
        }));
  
        // Update the status in the pass state
        setPass((prevPass) =>
          prevPass.map((item) =>
            item.id === manualInvoiceId ? { ...item, status: newStatus } : item
          )
        );
  
        console.log("Toggle and status updated successfully:", data);
      } else {
        alert("Failed to update the toggle state. Please try again.");
      }
    } catch (error) {
      console.error("Error updating toggle state:", error);
      alert("Failed to update the toggle state. Please try again.");
    }
  };

  const handleStatusChange = (selectedOption) => {
    setSelectedStatus(selectedOption.value);
  };

  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredBlogs = Array.isArray(manualInvoice)
  ? manualInvoice.filter((manualInvoice) => {
      const isStatus =
        !selectedStatus ||
        selectedStatus === "All" ||
        manualInvoice.status === selectedStatus;
      const values = Object.values(manualInvoice).map((value) =>
        typeof value === "string" ? value.toLowerCase() : ""
      );
      const isSearchMatch = values.some((value) =>
        value.includes(searchQuery.toLowerCase())
      );
      return isStatus && isSearchMatch;
    })
  : [];



  const openAdd_news = () => {
    closeAdd_news();
    setAdd_newsModalIsOpen(true);
  };

  const closeAdd_news = () => {
    setAdd_newsModalIsOpen(false);
  };
  const openUpdateManualInvoice = (
    giftCardId,
    name,
    email,
    remark,
    sold_at,
    mobileNumber,
    address
    // status
  ) => {
    setSelectedManualInvoiceId(giftCardId);
    setSelectedName(name);
    setSelectedEmail(email);
    setSelectedRemark(remark);
    setSelectedSoldAt(sold_at);
    setSelectedMobileNumber(mobileNumber);
    setSelectedAddress(address);
    // setSelectedStatus(status);

    closeUpdateManualInvoice();
    setUpdateManualInvoiceModalIsOpen(true);
  };
  
  const openDelete_invoice = (InvoiceId) => {
    setSelectedInvoiceId(InvoiceId);
    closeDeleteManualInvoice();
    setDeleteManualInvoiceIsOpen(true);
  };

  const closeDeleteManualInvoice = () => {
    setDeleteManualInvoiceIsOpen(null);
  };

  const closeUpdateManualInvoice = () => {
    setUpdateManualInvoiceModalIsOpen(false);
  };
  const openDeleteManualInvoice = (giftCardId) => {
    console.log('giftCardId')
    console.log(giftCardId)
    closeDeleteManualInvoice();
    setSelectedManualInvoiceId(giftCardId);
    setDeleteManualInvoiceIsOpen(true);
  };

  const openNewsComments = (id) => {
    closeNewsComments();
    setId(id);
    setNewsCommentsIsOpen(true);
  };

  const closeNewsComments = () => {
    setNewsCommentsIsOpen(null);
  };

  const openNewsSave = (id) => {
    closeNewsSave();
    setId(id);
    setNewsSaveIsOpen(true);
  };

  const closeNewsSave = () => {
    setNewsSaveIsOpen(null);
  };

  useEffect(() => {
    fetch(getManualInvoice)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const manualInvoice = data.data.ManualInvoice;
        setManualInvoice(manualInvoice);
        const initialToggleStates = manualInvoice.reduce((acc, item) => {
        acc[item.id] = item.status === "Active" ? 1 : 0;
        return acc;
        }, {});
        setToggleStates(initialToggleStates);
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const formatCreatedAt = (createdAt) => {
    if (!createdAt) {
      return "--";
    }
  
    const createdAtDate = new Date(createdAt);
  
    // Check if the date is valid
    if (isNaN(createdAtDate)) {
      return "--";
    }
  
    // Format the date part
    const datePart = createdAtDate.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  
    // Format the time part
    const timePart = createdAtDate.toLocaleTimeString("en-GB", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  
    return `${datePart} ${timePart}`;
  };
  

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  
  return (
    <div>
      <Header></Header>

      {modalAdd_newsIsOpen && <AddManualInvoice onClose={closeAdd_news} />}
      {/* {modalUpdateManualInvoiceIsOpen && <UpdateManualInvoice onClose={closeUpdateManualInvoice} />} */}
      {modalDeleteManualInvoiceIsOpen && (
              <DeleteManualInvoice
                Id={selectedManualInvoiceId}
                onClose={closeDeleteManualInvoice}
              />
            )}

      <main id="main" class="main">
        <div class="pagetitle">
          <h1>Manual Invoice</h1>
        </div>
        <section class="section">
          <div class="card p-2">
            <div class="parent col-md-12 row mt-2">
              <div class="col-md-6">
                <div class="child">
                  <input
                    onChange={handleSearchChange}
                    value={searchQuery}
                    id="myInput"
                    type="text"
                    style={{
                      borderRadius: "5px",
                      height: "35px",
                      padding: "10px",
                      border: "1px solid lightgrey ",
                    }}
                    placeholder="Search..."
                  />
                </div>
              </div>

              <div class="col-md-6" style={{ textAlign: "right" }}>
                <div class="child">
                  <a>
                    {" "}
                    <button
                      id="btnNewUser"
                      type="button"
                      data-toggle="modal"
                      data-target="#modalAdd"
                      class=" btn-round"
                      onClick={openAdd_news}
                    >
                      <i class="bi bi-plus-circle"></i> Add Manual Invoice
                    </button>
                  </a>
                </div>
              </div>
            </div>

            <div
              style={{ marginTop: "50px", display: "flex", marginLeft: "50%" }}
            >
              {/* <TailSpin color="black" width={50} /> */}
            </div>
            <div class="table-responsive">
              {isLoading === true ? (
                <div
                  style={{
                    marginTop: "50px",
                    display: "flex",
                    marginLeft: "50%",
                  }}
                >
                  <TailSpin color="black" width={50} />
                </div>
              ) : manualInvoice && manualInvoice.length > 0 ? (
                <table class="table table-striped" style={{ padding: "0rem" ,overflowY:"auto",width:"auto"}}>
                  <thead>
                    <tr>
                      <th scope="col">Sr.no</th>
                      <th scope="col">Invoice Number</th>
                      <th scope="col">Invoice Date</th>
                      <th scope="col">Bill To</th>
                      <th scope="col">Amount (Without GST)</th>
                      <th scope="col">Total Amount (With GST)</th>
                      <th scope="col" style={{ width: "200px" }}>Workshop</th>
                      <th scope="col">Number Of Tickets</th>
                      <th scope="col">Created_On</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredBlogs.map((manualInvoice, index) => (
                      <tr key={manualInvoice.id}>
                        <th scope="row">{index + 1}</th>
                        
                        <td>{manualInvoice.invoice_no || "---"}</td>

                        <td>{manualInvoice.invoice_date || "---"}</td>
                        <td>
                           <b>Name:</b> {manualInvoice.bill_to_name || "---"} <br/>
                           <b>Mobile No.:</b> {manualInvoice.bill_to_contact_no || "---"}<br/>
                           <b>Email:</b>{manualInvoice.bill_to_email || "---"}<br/>
                           <b>Address:</b>  {manualInvoice.bill_to_address || "---"}
                        </td>
                        <td>
                           {manualInvoice.description_amount || "0"}
                        </td>
                        <td>
                           {manualInvoice.total_amount || "0"} (<b>GST:</b>{manualInvoice.gst_amount || "0"})
                        </td>
                        <td>
                           <b>Name:</b> {manualInvoice.slot_name || "---"} <br/>
                           <b>Date:</b> {manualInvoice.slot_date || "---"}<br/>
                           <b>Time:</b> {manualInvoice.slot_time || "---"}
                        </td>
                        <td>{manualInvoice.no_tickets || "0"}</td>
                        <td>{formatCreatedAt(manualInvoice.created_at)}</td>
                        <td>
                        <div className="social-links mt-2">
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() => openDelete_invoice(manualInvoice.id)}
                          >
                            {" "}
                            <i class="bi bi-trash3"></i>
                          </a>
                          &nbsp;
                        </div>
                      </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <h3 style={{ textAlign: "center" }}> No data available </h3>
              )}

              {modalUpdateManualInvoiceIsOpen && (
                <Update_gift_card
                  giftCardId={selectedManualInvoiceId}
                  name={selectedName}
                  email={selectedEmail}
                  remark={selectedRemark}
                  sold_at={selectedSoldAt}
                  mobileNumber={selectedMobileNumber}
                  address={selectedAddress}
                //   status={selectednewsSummary}
                  onClose={closeUpdateManualInvoice}
                />
              )}

              {modalDeleteManualInvoiceIsOpen && (
                <DeleteManualInvoice
                  giftCardId={selectedManualInvoiceId}
                  onClose={closeDeleteManualInvoice}
                />
              )}
            </div>
          </div>
          <div>
            {" "}
            <p
              style={{
                backgroundColor: "#000",
                color: "white",
                padding: "10px",
              }}
            >
              {filteredBlogs.length || 0} Total
            </p>
          </div>
        </section>
      </main>
      <Footer></Footer>
    </div>
  );
}

export default ManualInvoice;
