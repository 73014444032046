import React, { useState, useEffect, useCallback } from "react";
import { ThreeDots } from "react-loader-spinner";
import "../../Pages/Admin/Style.css";
import "./add_discount_coupons.css";

function AddManualInvoice({ onClose }) {
  const [invoiceNo, setInvoiceNo] = useState("");
  const [invoiceDate, setInvoiceDate] = useState("");
  const [billToName, setBillToName] = useState("");
  const [billToAddress, setBillToAddress] = useState("");
  const [billToContactNo, setBillToContactNo] = useState("");
  const [billToEmail, setBillToEmail] = useState("");
  const [descriptionAmount, setDescriptionAmount] = useState("");
  const [slotName, setSlotName] = useState("");
  const [slotDate, setSlotDate] = useState("");
  const [slotTime, setSlotTime] = useState("");
  const [noTickets, setNoTickets] = useState("");
  const [code, setCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const validateFields = () => {
    if (!invoiceNo || !invoiceDate || !billToName || !billToAddress || !billToContactNo || !billToEmail || !descriptionAmount || !slotName || !slotDate || !slotTime || !noTickets || !code) {
      alert("Please fill in all fields");
      return false;
    }
    return true;
  };

  const handleSubmit = useCallback(async () => {
    if (!validateFields()) return;

    setIsLoading(true);
    const requestBody = {
      invoice_no: invoiceNo,
      invoice_date: invoiceDate,
      bill_to_name: billToName,
      bill_to_address: billToAddress,
      bill_to_contact_no: billToContactNo,
      bill_to_email: billToEmail,
      description_amount: descriptionAmount,
      slot_name: slotName,
      slot_date: slotDate,
      slot_time: slotTime,
      no_tickets: noTickets,
      code,
    };

    try {
      const response = await fetch("http://127.0.0.1:8000/api/manual_invoice/add-manual_invoice/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "X-CSRFTOKEN": "6kKzU79TCKm4Hs06tt9G91WeQGjvgdGz",
        },
        body: JSON.stringify(requestBody),
      });

      const responseData = await response.json();
      
      if (response.ok) {
        if (responseData.status === 201) {
          alert("Manual Invoice Created Successfully");
          onClose();
          window.location.reload();
        } else {
          alert("Error: " + (responseData.message || "Something went wrong"));
        }
      } else {
        alert("Error: " + (responseData.message || "Something went wrong"));
      }
    } catch (error) {
      alert("An error occurred: " + error.message);
    } finally {
      setIsLoading(false);
    }
  }, [invoiceNo, invoiceDate, billToName, billToAddress, billToContactNo, billToEmail, descriptionAmount, slotName, slotDate, slotTime, noTickets, code, onClose]);

  return (
    <div className="popup-background">
      <div className="popup">
        <div className="modal-content" style={{ padding: "2%", backgroundColor: "#fff", borderRadius: "5px" }}>
          <div className="modal-header">
            <h5 className="modal-title" style={{ marginBottom: "2%" }}><b>Add Manual Invoice</b></h5>
            <button type="button" className="close" onClick={onClose}>&times;</button>
          </div>

          <div className="modal-body">
            <form>
              {[{ label: "Invoice No", value: invoiceNo, setter: setInvoiceNo },
                { label: "Invoice Date", value: invoiceDate, setter: setInvoiceDate, type: "date" },
                { label: "Bill To Name", value: billToName, setter: setBillToName },
                { label: "Bill To Address", value: billToAddress, setter: setBillToAddress },
                { label: "Bill To Contact No", value: billToContactNo, setter: setBillToContactNo, type: "tel" },
                { label: "Bill To Email", value: billToEmail, setter: setBillToEmail, type: "email" },
                { label: "Description Amount", value: descriptionAmount, setter: setDescriptionAmount },
                { label: "Slot Name", value: slotName, setter: setSlotName },
                { label: "Slot Date", value: slotDate, setter: setSlotDate, type: "date" },
                { label: "Slot Time", value: slotTime, setter: setSlotTime, type: "time" },
                { label: "No. of Tickets", value: noTickets, setter: setNoTickets, type: "number" },
                { label: "Workshop Code", value: code, setter: setCode }].map(({ label, value, setter, type = "text" }, index) => (
                <div className="form-group" key={index}>
                  <label className="small mb-1">{label} <font color="red">*</font></label>
                  <input type={type} className="form-control" placeholder={label} value={value} onChange={(e) => setter(e.target.value)} />
                </div>
              ))}
            </form>
          </div>

          <div className="modal-footer">
            <button type="button" className="btn btn-danger" onClick={onClose}>Close</button>
            {isLoading ? <ThreeDots color="#000" height={50} width={50} /> : <button type="button" className="btn btn-primary" onClick={handleSubmit}>Add</button>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddManualInvoice;
