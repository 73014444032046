import React from "react";
import { TailSpin } from "react-loader-spinner";
import "../../Pages/Admin/Style.css";
import "../../Components/Admin_components/update_packages.css";

function ViewTransactionSplittedGiftcards({ gift_cards, onClose }) {
  const formatCreatedAt = (createdAt) => {
    const createdAtDate = new Date(createdAt);
    return createdAtDate.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  };

  return (
    <div>
      <div className="popup-background">
        <div className="popup">
          <div
            className="modal-content"
            style={{
              width: "100%",
              height: "80%",
              padding: "2%",
              backgroundColor: "#fff",
              borderRadius: "5px",
            }}
          >
            <div
              className="modal-header"
              style={{
                width: "100%",
                marginBottom: "3%",
                borderBottom: "solid 1px #7e7a7a8c",
              }}
            >
              <h5 className="modal-title" id="modalAddTitle" style={{ marginBottom: "2%" }}>
                <b>Splitted Gift Cards</b>
              </h5>

              <button type="button" onClick={onClose} className="close" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body" style={{ overflowY: "scroll", padding: "2%" }}>
              <div className="table-responsive">
                {gift_cards.length > 0 ? (
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Sr.No</th>
                        <th scope="col">Card Code</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Sold At</th>
                        <th scope="col">Expiry_Date</th>
                        <th scope="col">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {gift_cards.map((card, index) => (
                        <tr key={card.id}>
                          <th scope="row">{index + 1}</th>
                          <td>{card.card_code}</td>
                          <td>₹{card.amount.toFixed(2)}</td>
                          <td>₹{card.sold_at.toFixed(2)}</td>
                          <td>{formatCreatedAt(card.expiry_date)}</td>
                          <td>{card.status}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <h3 style={{ textAlign: "center" }}>No data available</h3>
                )}
              </div>
            </div>

            <div
              className="modal-footer"
              style={{
                padding: "2%",
                marginTop: "3%",
                borderTop: "solid 1px #7e7a7a8c",
              }}
            >
              <button type="button" className="btn btn-danger" onClick={onClose}>
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewTransactionSplittedGiftcards;
